<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="hazards"
              :sort-mapping="sortMapping"
              :title="'shipments made'"
              :total="hazardsTotal"
              :fetch-path="'labourHazards/fetch'"
              :filter-path="'labourHazards/filterShipments'"
              :export-path="'labourHazards/exportShipments'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="showInfoSheet(field, 'docs')">
                        <i
                          class="me-50"
                          data-feather="file"
                        /> Show docs
                      </b-dropdown-item>
                      <b-dropdown-item @click="showInfoSheet(field, 'audience')">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> Show audience
                      </b-dropdown-item>
                      <b-dropdown-item @click="$router.push({name: 'admin.labour-hazards.edit', params: { id: field.id }})">
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteItem(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div class="offcanvas-body">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Researcher</label>
                    <v-select
                      label="name"
                      :options="users"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                      @input="saveFilter($event, 'researcher')"
                    />
                  </div>
                  <hr>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Section</label>
                    <v-select
                      label="name"
                      :options="sections"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'safeties/filter')"
                      @input="saveFilter($event, 'section')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Subsection</label>
                    <v-select
                      label="name"
                      :options="subsections"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'safeties/filter')"
                      @input="saveFilter($event, 'subsection')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Host</label>
                    <v-select
                      label="name"
                      :options="hosts"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'institutions/filter')"
                      @input="saveFilter($event, 'host')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Title</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      @change="saveFilter($event.target.value, 'title')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Document name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      @change="saveFilter($event.target.value, 'document_name')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Follow</label>
                    <select
                      id=""
                      name=""
                      class="form-select"
                      @input="saveFilter($event.target.value, 'follow')"
                    >
                      <option value="">
                        All
                      </option>
                      <option value="true">
                        Yes
                      </option>
                      <option value="false">
                        No
                      </option>
                    </select>
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Need feedback</label>
                    <select
                      id=""
                      name=""
                      class="form-select"
                      @input="saveFilter($event.target.value, 'need_feedback')"
                    >
                      <option value="">
                        All
                      </option>
                      <option value="true">
                        Yes
                      </option>
                      <option value="false">
                        No
                      </option>
                    </select>
                  </div>
                  <hr>
                  <h4>Shipping date</h4>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >From year</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="YYYY"
                      @change="saveFilter($event.target.value, 'shipping_from_year')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >To year</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="YYYY"
                      @change="saveFilter($event.target.value, 'shipping_to_year')"
                    >
                  </div>
                  <hr>
                  <h4>Renew date</h4>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >From year</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="YYYY"
                      @change="saveFilter($event.target.value, 'renew_from_year')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >To year</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="YYYY"
                      @change="saveFilter($event.target.value, 'renew_to_year')"
                    >
                  </div>
                </div>
              </template>
              <template #info-sheet-item="{item, type}">
                <div
                  v-if="type == 'docs'"
                  class="offcanvas-body offcanvas-body--view"
                >
                  <div
                    v-for="file in item.files_h"
                    :key="`f-${file.id}`"
                    class="card shadow-card"
                  >
                    <div class="card-header">
                      <h5>{{ file.title }}<a
                        :href="file.url"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Open document"
                        class="ms-1"
                      ><i data-feather="external-link" /></a></h5>
                    </div>
                    <div class="card-body">
                      <table class="table border-tds">
                        <tr>
                          <td>Follow</td>
                          <td>
                            <span
                              v-if="file.follow"
                              class="badge bg-light-success"
                            >Yes</span>
                            <span
                              v-else
                              class="badge bg-light-danger"
                            >No</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Need feedback</td>
                          <td>
                            <span
                              v-if="file.need_feedback"
                              class="badge bg-light-success"
                            >Yes</span>
                            <span
                              v-else
                              class="badge bg-light-danger"
                            >No</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Deadline</td>
                          <td>{{ file.deadline }}</td>
                        </tr>
                        <tr>
                          <td>Year</td>
                          <td>{{ file.year }}</td>
                        </tr>
                        <tr>
                          <td>Renew</td>
                          <td>{{ file.renew_year }}</td>
                        </tr>
                        <tr>
                          <td>Archived data</td>
                          <td>{{ file.archived_data ? file.archived_data : '-' }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="offcanvas-body offcanvas-body--view"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Search ICREAs</label>
                    <input
                      type="text"
                      class="form-control"
                      @change="searchAudience($event.target.value, item.id)"
                    >
                  </div>
                  <hr>
                  <h5>
                    List of audience
                    <span class="badge bg-light-info">{{ item.shared_with }}</span>
                    <a
                      href="#"
                      class="btn btn-icon rounded-circle btn-flat-secondary ms-2"
                      @click="searchAudience('all', item.id)"
                    >Show all</a>
                  </h5>
                  <div
                    v-if="loading"
                    class="loading"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                  <ul
                    v-if="!loading"
                    class="list-group"
                  >
                    <template
                      v-for="au in audience"
                    >
                      <li
                        v-for="a in au"
                        :key="a.id"
                        class="list-group-item"
                      >
                        {{ a.name }}
                      </li>
                    </template>
                  </ul>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import vSelect from 'vue-select'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    vSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      sortedField: 'Date',
      defaultFields: [
        {
          name: 'Date',
          checked: true,
          order: 1,
        },
        {
          name: 'Section',
          checked: true,
          order: 2,
        },
        {
          name: 'Subsection',
          checked: true,
          order: 3,
        },
        {
          name: 'Title',
          checked: true,
          order: 4,
        },
        {
          name: 'Docs',
          checked: true,
          order: 5,
        },
        {
          name: 'Audience',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Date: 'created_at',
        Section: 'section.name',
        Subsection: 'subsections_names',
        Title: 'title',
        Docs: 'files_h.length',
        Audience: 'shared_with',
      },
      title: 'Occupational hazards sends',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      hazards: 'labourHazards/labourHazards',
      hazardsTotal: 'labourHazards/labourHazardsTotal',
      audience: 'labourHazards/audience',
      // Filters
      users: 'users/fakeUsers',
      sections: 'safeties/safeties',
      subsections: 'safeties/safeties',
      hosts: 'institutions/institutions',
      // END filters
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'labour-hazard-shipments')
    await this.$store.dispatch('labourHazards/fetch')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'labour-hazard-shipments',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async onSearch(search, store) {
      search.active = true
      if (search !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    deleteItem(id) {
      Vue.swal({
        title: 'Delete the selected items?',
        text: "Are you sure? You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete!',
      }).then(result => {
        if (result.isConfirmed && id) {
          this.$store.dispatch('labourHazards/delete', id).then(() => {
            this.$toastr.success('', 'Files deleted successfully!')
            this.$store.dispatch('labourHazards/fetch')
          })
        } else if (this.selectedItems.length === 0) {
          this.$toastr.error('', 'Operation cancelled!')
        }
      })
    },
    async searchAudience(search, id) {
      this.loading = true
      if (search.length > 2) {
        await this.$store.dispatch('labourHazards/fetchAudience', { search, id })
      }
      this.loading = false
    },
  },
}
</script>
